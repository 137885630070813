import React from "react"

import { Box } from "@material-ui/core"

import Hero from "../sections/hero"
import Section from "../sections/section"
import Card from "../blocks/card"
import Carousel from "../blocks/carousel"
import Map from "../blocks/map"
import Seo from "../modules/seo"

// import { transformStrapiData } from "../functions/transform-strapi-data"
import { useQueryPage } from "../functions/use-query-page"

const PageTemplate = props => {
  // React.useEffect(() => {
  //   console.log("=== Page ===", props)
  // })

  const previewData = useQueryPage({
    pageId: props.pageId,
  })
  // console.log("=== previewData ===", previewData)

  const { page } = props.pageContext || previewData
  // console.log("=== Page data ===", page)

  // const defaultLabels =
  //   props.pageContext?.defaultLabels || previewData?.site.defaultLabels
  const defaultPages =
    props.pageContext?.defaultPages || previewData?.site.defaultPages

  const sections = props.pageContext.sections

  // const [sections, setSections] = React.useState(null)
  // React.useEffect(() => {
  //   if (page) {
  //     // console.log("page.flexibleContent", page.flexibleContent)
  //     setSections(
  //       transformStrapiData({
  //         contentItems: page.flexibleContent,
  //         defaultLabels: defaultLabels,
  //         defaultPages: defaultPages,
  //         pages: props.pageContext?.pages,
  //         articles: props.pageContext?.articles,
  //         locations: props.pageContext?.locations,
  //         events: props.pageContext?.events,
  //         environments: props.pageContext?.environments,
  //         entities: props.pageContext?.entities,
  //         taxons: props.pageContext?.taxons,
  //         documents: props.pageContext?.documents,
  //       })
  //     )
  //   }
  // }, [page, defaultLabels, defaultPages])
  // // console.log("=== Page section ===", sections)

  return (
    <React.Fragment>
      {page && (
        <Seo
          title={page.title + (page.subtitle ? " | " + page.subtitle : "")}
          slug={"/" + page.slug}
          image={page.image?.file?.childImageSharp?.fixed?.src}
          description={page.description}
        />
      )}

      {previewData && (
        <Section padding={true} title="Preview mode" titleColor="DARK">
          <b>Page is not optimised !</b>
        </Section>
      )}
      {page && page.displayCover !== false && (
        <React.Fragment>
          <Section padding={false}>
            <Hero
              {...page}
              imageFluid={page.image?.file?.childImageSharp?.fluid}
              imageCaption={page.image?.caption}
              imageUrl={
                // process.env.GATSBY_STRAPI_HOST +
                page.image?.formats?.thumbnail?.url
              }
              background={"DARK"}
              height={"SIZE_50"}
            />
          </Section>
        </React.Fragment>
      )}

      {sections?.length > 0 &&
        sections.map((section, index) => {
          return (
            <React.Fragment key={index}>
              <Section
                {...section}
              // background={"SECONDARY"}
              //
              // anchorName={section.anchorName}
              // background={section.background}
              // height={section.height}
              // padding={section.padding}
              // title={section.title}
              // titleColor={section.titleColor}
              >
                {section.layout === "GRID" &&
                  section.items?.length > 0 &&
                  section.items.map((item, index) => {
                    // console.log("GRID ITEM PROPS", item)

                    return (
                      <React.Fragment key={index}>
                        <Card {...item} />
                      </React.Fragment>
                    )
                  })}

                {section.layout === "CAROUSEL" && (
                  <React.Fragment>
                    <Carousel itemsCount={section.items?.length}>
                      {section.items.length > 0 &&
                        section.items.map((item, index) => {
                          // console.log("CAROUSEL ITEM PROPS", item)

                          return (
                            <React.Fragment key={index}>
                              <Box
                              // className={classes.kenBurns}
                              >
                                <Hero {...item} />
                              </Box>
                            </React.Fragment>
                          )
                        })}
                    </Carousel>
                  </React.Fragment>
                )}

                {section.layout === "MAP" && (
                  // section.items?.length > 0 &&
                  <Map
                    locations={section.items}
                    displayFilter={true}
                    defaultPages={defaultPages}
                    {...section}
                  />
                )}
              </Section>
            </React.Fragment>
          )
        })}
    </React.Fragment>
  )
}

export default PageTemplate
