import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = props => {
  const { description, lang, title, slug, image } = props
  // console.log("Seo", props)

  const data = useStaticQuery(
    graphql`
      query {
        strapi {
          site {
            title
            author
            description
            url
            image {
              url
              file {
                childImageSharp {
                  fixed(width: 1200) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const { site } = data.strapi

  const pageUrl = site.url + slug + "/"

  const metaImage =
    site.url + (image || site.image.file.childImageSharp.fixed.src)
  const metaDescription =
    (description ? description + " | " : "") + site.description
  const metaTitle =
    title + " | " + site.title + (site.author ? " | " + site.author : "")
  const metaAuthor = site.author

  const trimString = (string, chars) => string.substring(0, chars - 3) + "..."

  return (
    <React.Fragment>
      {pageUrl && metaTitle && metaDescription && metaImage && metaAuthor && (
        <Helmet>
          {/* START : General tags */}
          <html lang={lang} />
          <title>{trimString(metaTitle, 65)}</title>
          <meta name="description" content={trimString(metaDescription, 160)} />
          <link rel="canonical" href={pageUrl} />
          <link rel="image_src" href={metaImage} />
          {/* STOP : General tags */}

          {/* START : OpenGraph tags */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={pageUrl} />
          <meta property="og:title" content={trimString(metaTitle, 95)} />
          <meta
            property="og:description"
            content={trimString(metaDescription, 200)}
          />
          <meta property="og:image" content={metaImage} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="627" />
          {/* STOP : OpenGraph tags */}

          {/* START : Twitter Card tags */}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content={trimString(metaTitle, 70)} />
          <meta
            name="twitter:description"
            content={trimString(metaDescription, 200)}
          />
          <meta name="twitter:image" content={metaImage} />
          <meta name="twitter:creator" content={metaAuthor} />
          {/* STOP : Twitter Card tags */}
        </Helmet>
      )}
    </React.Fragment>
  )
}

Seo.defaultProps = {
  lang: `fr`,
  slug: ``,
  description: ``,
}

export default Seo
