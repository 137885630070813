import { gql, useQuery } from "@apollo/client"

const { getPageQuerySchema } = require(`../data/graphql-queries`)

export const useQueryPage = props => {
  const pageQuerySchema = getPageQuerySchema({
    queryBy: "apollo",
    pageId: props.pageId,
  })
  // console.log("pageQuerySchema", pageQuerySchema)

  const { data, error } = useQuery(gql(pageQuerySchema), {
    errorPolicy: "ignore",
    skip: !props.pageId,
  })
  // data && console.log("useQueryPage data", data.page)

  error && console.log(error)

  return data || null
}
